<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    v-model="show"
    persistent
  >
    <template>
      <v-card>
        <v-card-text>
          <div class="text-h5 pa-12">
            Debido a continuas transgresiones se ha limitado esta interacci&oacute;n.
          </div>
          <div class="text-h6" style="font-style: italic;padding-left: 40px;">
            Se ha notificado al administrador
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            @click="onClose"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
}
</script>

